import React from 'react';
import Link from '/src/components/LocalizedLink';
import Layout from '../../components/Layout';

import IconArrowRightRed from '../../assets/images/svg/arrow-right-red.inline.svg';
import Faq from '../../components/Faq';

import imgRmain from '../../assets/images/how-to-make-a-good-application/Photo 2 (6).png';
import imgDots from '../../assets/images/application-process/Dots 3.svg';
import '../../assets/styles/pages/how-to-make-a-good-application-page.scss';
import IconWhatsApp from '../../assets/images/svg/whatsapp.inline.svg';

const devidedString = `
No, to do the course you have to demonstrate a high level of competence in reading, writing, 
speaking and listening in English as appropriate to a teacher of the language. If English 
is not your first language you should have English that is at C1 level of the Common European 
Framework of Reference (CEFR) in all of the four skills: reading, writing, speaking and listening.
<br/><br/>
If you have any more questions about the application process please feel free to contact our coordination 
team at classes@ilabarcelona.com.`;

const standOut = `
A great application is one that shows a <strong>good level of language awareness</strong> in the task and the candidate displays a <strong>great attitude towards teaching and learning</strong> during the interview.
                <span>We love to receive applications from people that are:</span>
                <ul class='faq-list'>
                    <li class='faq-list__item'>Passionate about teaching and learning</li>
                    <li class='faq-list__item'>Excited about studying abroad</li>
                    <li class='faq-list__item'>Are open to learning new ideas</li>
                    <li class='faq-list__item'>Are willing to learn and take on constructive feedback</li>
                    <li class='faq-list__item'>Have the stamina and appetite for one month of intensive study</li>
                </ul>
                <p class="mt-15">If you tick all of these boxes, we want to hear from you! Chances are you’ll be a great teacher and we’d love to work with you.</p> 
`;

const intervierw = `
The Trinity CertTESOL course is as demanding as the first year of an undergraduate degree so we have to ascertain that you can deal with the academic demands and time pressure of the course. We also have to confirm that your level of speaking, reading, writing and listening are sufficiently high in order to teach English.
<br/><br/>
The course involves one month of intensive studying and is not suited to everyone, it is best find out your suitability for English teaching before you have invested in the course.
`;
const test = `
Applicants are sometimes surprised at being asked to display grammar knowledge before the course even starts, as they feel this is part of the reason for attending the course in the first place. In truth, the grammar questions before the course are not aimed at testing your inherent ability, but your willingness to research the answers and find out more. Before the course we are looking for <strong>potential</strong>.
<br/><br/>
Also, working your way through grammar exercises will <strong>give you a taste of the course material</strong>, and for some, might be sufficient to convince them that teaching English is just not for them after all. It is better to find out now rather than halfway through the course!
`;
const faqs = [
  {
    id: 1,
    title: 'How can I make my application stand out?',
    description: standOut,
  },
  {
    id: 2,
    title: 'Why do I have to do an interview, can’t I just sign up?',
    description: intervierw,
  },
  {
    id: 3,
    title: 'Why do you test my language / grammar awareness before the course?',
    description: test,
  },
  {
    id: 4,
    title: 'I don’t have a university degree, can I still do the course?',
    description:
      'Yes, in order to be accepted onto the Trinity CertTESOL course you need to demonstrate sufficient qualifications / work experience to be accepted onto an undergraduate degree course, but a degree itself is not essential.',
  },
  {
    id: 5,
    title: 'English isn’t my first language, is that a problem?',
    description: devidedString,
  },
];

const HowToMakeaGoodApplication = () => {
  return (
    <Layout
      title={{ id: 'howToMakeGoodApp.seo.title' }}
      description={{ id: 'howToMakeGoodApp.seo.description' }}
    >
      <div className="how-to-make-a-good-application-page">
        <section className="c-first-section c-first-section--md">
          <div className="container">
            <h1 className="title">How to Make a Good Application</h1>
          </div>
        </section>
        <section className="second-section">
          <div className="c-application-process-section">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-7 col-12">
                  <div className="left">
                    <h1 className="c-title-34">
                      The Application Process for The Trinity CertTESOL Course at TEFL Iberia Is As
                      Follows:
                    </h1>
                    <div className="left__wrapper">
                      <div className="circle">
                        <span>1</span>
                      </div>
                      <div className="left__text">
                        <h3 className="c-title-18">You fill in the application form online.</h3>
                      </div>
                    </div>
                    <div className="left__wrapper">
                      <div className="circle">
                        <span>2</span>
                      </div>

                      <div className="left__text">
                        <h3 className="c-title-18">
                          We send you a short language awareness task to be completed online.
                        </h3>
                      </div>
                    </div>
                    <div className="left__wrapper">
                      <div className="circle">
                        <span>3</span>
                      </div>
                      <div className="left__text">
                        <h3 className="c-title-18">
                          You complete an interview with the course coordinator (via phone / Skype /
                          FaceTime / Whatsapp / in person at the school).
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-5 col-12">
                  <div className="right">
                    <img src={imgRmain} alt="" className="right__img" />
                    <img src={imgDots} alt="" className="dots" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="third-section c-faqs-section">
          <div className="container">
            <div className="c-faqs-section__title">
              <h2 className="c-title-34">Frequently Asked Questions</h2>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <Faq description={faqs[0].description} title={faqs[0].title} />
                <Faq description={faqs[1].description} title={faqs[1].title} />
                <Faq description={faqs[2].description} title={faqs[2].title} />
              </div>
              <div className="col-lg-6">
                <Faq description={faqs[3].description} title={faqs[3].title} />
                <Faq description={faqs[4].description} title={faqs[4].title} />
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default HowToMakeaGoodApplication;
